@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";



@font-face {
  font-family: "Roobert Regular";
  src: url('./assets/fonts/Roobert-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roobert Light";
  src: url('./assets/fonts/Roobert-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roobert Medium";
  src: url('./assets/fonts/Roobert-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roobert SemiBold";
  src: url('./assets/fonts/Roobert-SemiBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roobert Bold";
  src: url('./assets/fonts/Roobert-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roobert Regular";
}

body, #root, .App {
  min-width: 100vw;
  min-height: 100vh;
  background-color: black;
}

/* CHAT MESSAGE OVERRIDES */

@keyframes animateInMessage {
  from {
    opacity: 0;
    transform: translateY(100%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.cs-message-list > section:last-of-type {
  padding-bottom: 70px;
}

.cs-message__content {
  padding: 10px 20px 14px;
  font-family: "Roobert Medium";
  font-size: 1rem;
  line-height: 1.5rem;
}

.animate-in.count-0 .cs-message__content {
  animation-duration: 500ms;
  animation-name: animateInMessage;
}

.animate-in.count-0 .cs-message__content > .cs-message__text-content {
  animation-duration: 500ms;
  animation-delay: 100ms;
  animation-name: animateInMessage;
}

.cs-message--incoming .cs-message__content {
  color: white;
}

.cs-message--incoming.message--text .cs-message__content {
  background-color: #E7EAF2 !important;
  color: white;
}
.cs-message--incoming.count-5.message--text .cs-message__content {
  background-color: #E7EAF2 !important;
}
.cs-message--incoming.count-4.message--text .cs-message__content {
  background-color: #CED4E4 !important;
}
.cs-message--incoming.count-3.message--text .cs-message__content {
  background-color: #9EAACA !important;
}
.cs-message--incoming.count-2.message--text .cs-message__content {
  background-color: #6D7FAF !important;
}
.cs-message--incoming.count-1.message--text .cs-message__content {
  background-color: #3D5595 !important;
}
.cs-message--incoming.count-0.message--text .cs-message__content {
  background-color: #0C2A7A !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: #EBEB67 !important;
  color: #0C2A7A;
}


.cs-message.cs-message--incoming.cs-message--last,
.cs-message.cs-message--incoming.cs-message--single,
.cs-message.cs-message--outgoing.cs-message--last,
.cs-message.cs-message--outgoing.cs-message--single {
  padding-bottom: 10px;
}
.cs-message.cs-message--incoming .cs-message__content ,
.cs-message.cs-message--outgoing .cs-message__content {
  position: relative;
}

.cs-message.cs-message--incoming.cs-message--last.message--text .cs-message__content {
  border-radius: 0 0.7em 0.7em 0;
}
.cs-message.cs-message--incoming.cs-message--first.message--text .cs-message__content,
.cs-message.cs-message--incoming.cs-message--single.message--text .cs-message__content {
  border-radius: 0.7em 0.7em 0.7em 0;
}

.cs-message.cs-message--outgoing.cs-message--last .cs-message__content {
  border-radius: 0.7em 0 0 0.7em;
}
.cs-message.cs-message--outgoing.cs-message--first .cs-message__content,
.cs-message.cs-message--outgoing.cs-message--single .cs-message__content {
  border-radius: 0.7em 0.7em 0 0.7em;
}

.cs-message.cs-message--incoming.cs-message--last.message--text .cs-message__content:after,
.cs-message.cs-message--incoming.cs-message--single.message--text .cs-message__content:after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #0C2A7A;
  position: absolute;
  left: 0;
  bottom: -7px;
}


.cs-message.cs-message--outgoing.cs-message--last .cs-message__content:after,
.cs-message.cs-message--outgoing.cs-message--single .cs-message__content:after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #EBEB67;
  position: absolute;
  right: 0;
  bottom: -7px;
}

.cs-message-list__scroll-wrapper>.cs-message.message--cohort-hero,
.cs-message-list__scroll-wrapper>.cs-message.message--results,
.cs-message-list__scroll-wrapper>.cs-message.message--deals,
.cs-message-list__scroll-wrapper>.cs-message.message--sign-up {
  max-width: 100%;
}

.cs-message.message--cohort-hero .cs-message__content-wrapper,
.cs-message.message--results .cs-message__content-wrapper,
.cs-message.message--deals .cs-message__content-wrapper,
.cs-message.message--sign-up .cs-message__content-wrapper {
  width: 100%;
}

.cs-message.message--cohort-hero .cs-message__content,
.cs-message.message--results .cs-message__content,
.cs-message.message--deals .cs-message__content,
.cs-message.message--sign-up .cs-message__content {
  margin-top: 80px;
  margin-bottom: 80px;
  position: relative;
  background-color: #0C2A7A !important;
  border-radius: 0;
}

.cs-message.cs-message--incoming.cs-message--single.message--cohort-hero .cs-message__content,
.cs-message.cs-message--incoming.cs-message--single.message--results .cs-message__content,
.cs-message.cs-message--incoming.cs-message--single.message--deals .cs-message__content,
.cs-message.cs-message--incoming.cs-message--single.message--sign-up .cs-message__content,
.cs-message.cs-message--incoming.cs-message--last .cs-message__content {
  border-radius: 0;
}

.cs-message.message--cohort-hero .cs-message__content:before,
.cs-message.message--results .cs-message__content:before,
.cs-message.message--deals .cs-message__content:before,
.cs-message.message--sign-up .cs-message__content:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 83px;
  top: -82px;
  left: 0;
  background-image: url('./assets/svg/diagonal-top.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.cs-message.message--cohort-hero .cs-message__content:after,
.cs-message.message--results .cs-message__content:after,
.cs-message.message--deals .cs-message__content:after,
.cs-message.message--sign-up .cs-message__content:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 83px;
  bottom: -82px;
  left: 0;
  background-image: url('./assets/svg/diagonal-top.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  transform: rotateY(180deg) rotateX(180deg);
}

.cs-message-list.has-typing-indicator .scrollbar-container {
  padding-bottom: 22px;
}

.cs-message-list__scroll-wrapper>.cs-message:nth-last-child(4) {
  margin-bottom: 44px;
}

.cs-message-list.has-typing-indicator .cs-message-list__scroll-wrapper>.cs-message:nth-last-child(4) {
  margin-bottom: 82px;
  padding-bottom: 0;
} 

@media (min-width: 1024px) and (min-height: 776px) {
  .cs-message-list__scroll-wrapper>.cs-message:nth-last-child(4) {
    margin-bottom: 66px;
  }  

  .cs-message-list.showing-choices .cs-message-list__scroll-wrapper>.cs-message:nth-last-child(4) {
    margin-bottom: 44px;
  }

  .cs-message-list.has-typing-indicator .cs-message-list__scroll-wrapper>.cs-message:nth-last-child(4) {
    margin-bottom: 104px;
  }
}

.cs-message-list .cs-typing-indicator {
  height: 48px;
  padding-top: 3px;
  padding-left: 13px;
  margin-bottom: 46px;
  background-color: transparent;
}

@media (min-width: 1024px) and (min-height: 776px) {
  .cs-message-list .cs-typing-indicator {
    margin-bottom: 68px;
  }
}

.cs-typing-indicator__indicator {
  background-color: #0C2A7A;
  color: white;
  padding: 14px 20px;
  border-radius: 0 0.7em 0.7em 0;
  position: relative;
}

.cs-typing-indicator__indicator:after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #0C2A7A;
  position: absolute;
  left: 0;
  bottom: -7px;
}

.cs-typing-indicator__dot {
  background-color: white;
  width: 10px;
  height: 10px;
}

.cs-typing-indicator__dot:not(:last-child) {
  margin-right: 10px;
}


/* SLIDER */
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: transparent;
  outline: none;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 44px;
  height: 44px;
  background-color: #0C2A7A;
  cursor: pointer;
  border: 2px solid #20B2D6;
  border-radius: 100%;
  background-image: url('./assets/icons/slider-pull.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}